import React, { useEffect, useState } from 'react'
import styles from '../Testing/Testing.module.css'
import { connect } from 'react-redux'
import ScreenSizeContainer from '../../utils/ScreenSizeContainer'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../utils/Button'
import { fetchUserData } from '../../../model/common/User/actions'
import WebRtcTesting from './WebRtcTesting'
import TaskTesing from './TaskTesting'
import repeat from '../../../images/repeat.svg'
import task_img from '../../../images/task_img.png'
import Header from '../Enrollment/Header'
import TaskControls from '../Enrollment/TaskControls'

const mapStateToProps = state => ({
  userData: state.user,
  authData: state.auth,
  orgList: state.orgList,
})

const mapDispatchToProps = {
  fetchUserData,
}

const TestingContainer = ({ authData, userData, orgList }) => {
  let history = useHistory()

  if (!userData) {
    history.push('/profile')
  }

  const [count, setCount] = useState(0)
  const [showTask, setShowTask] = useState(false)
  const [taskList, setTaskList] = useState(false)
  const [ticket, setTicket] = useState(false)
  const [generateNumber, setGenerateNumber] = useState(false)
  const own_organization = orgList && orgList.find(item => authData.base === item.Name)

  const task = [
    {
      id: 1,
      task_order: 1,
      type_task: 'checkbox',
      task_lesson: { lesson_name: 'Русский язык' },
      task_question: {
        text_question:
          'Отметьте в таблице ряд слов, в которых все согласные звуки звонкие. Поставьте ' +
          'галочку (✓) напротив верного ответа.',
      },
      task_answer: [
        { id: 1, answer_text: 'Задача, щавель' },
        { id: 2, answer_text: 'Герой, вода' },
        { id: 3, answer_text: 'Родители, ружьё' },
        { id: 4, answer_text: 'Шиповник, зефир' },
      ],
    },
    {
      id: 2,
      task_order: 2,
      type_task: 'select',
      task_lesson: { lesson_name: 'Русский язык', lesson_theme: 'Autumn' },
      task_question: {
        text_question:
          'Установите соответствие между словами и их характеристикой: ' +
          'к каждой позиции из первого столбца подберите соответствующую позицию из' +
          'второго столбца',
      },
      task_answer: [
        {
          id: 1,
          task_text: 'А) водица, водяной',
          task_answer: [
            { answer_text: '1) Форма одного и того же слова' },
            { answer_text: '2) Однокоренные слова' },
            { answer_text: '3) Не однокоренные слова' },
          ],
        },
        {
          id: 2,
          task_text: 'Б) вода, водой',
          task_answer: [
            { answer_text: '1) Форма одного и того же слова' },
            { answer_text: '2) Однокоренные слова' },
            { answer_text: '3) Не однокоренные слова' },
          ],
        },
        {
          id: 3,
          task_text: 'В) водитель, подводник',
          task_answer: [
            { answer_text: '1) Форма одного и того же слова' },
            { answer_text: '2) Однокоренные слова' },
            { answer_text: '3) Не однокоренные слова' },
          ],
        },
        {
          id: 4,
          task_text: 'Г) водитель, водительский',
          task_answer: [
            { answer_text: '1) Форма одного и того же слова' },
            { answer_text: '2) Однокоренные слова' },
            { answer_text: '3) Не однокоренные слова' },
          ],
        },
      ],
    },
    {
      id: 3,
      task_order: 3,
      type_task: 'checkbox',
      task_lesson: { lesson_name: 'Русский язык' },
      task_question: {
        text_question:
          'Поставьте знак ударения в следующих словах и ответьте галочкой (✓) ' +
          'в таблице слова, в которых ударение падает в 1-й слог.',
      },
      task_answer: [
        { id: 1, answer_text: 'срЕдствА' },
        { id: 2, answer_text: 'звОнИт' },
        { id: 3, answer_text: 'стОлЯр' },
        { id: 4, answer_text: 'дОвЕрхУ' },
      ],
    },
    {
      id: 4,
      task_order: 4,
      type_task: 'order',
      task_lesson: { lesson_name: 'Русский язык' },
      task_question: {
        text_question:
          'В какой последовательности надо расположить предложения, чтобы' +
          'получился текст? Запишите последовательность цифр в отведенное для ответа место',
      },
      task_answer: [
        {
          id: 1,
          answer_text:
            'То вдруг вспомнится Обезьяна, не узнавшая себя в Зеркале' +
            ', то ' +
            'неожиданно предстанет перед глазами ничтожная Моська, которая, визгливо',
        },
        {
          id: 2,
          answer_text: 'Поэтому Н.В Гоголь назвал басни И.А Крылова «книгой мудрости» русского ' + 'народа',
        },
        {
          id: 3,
          answer_text:
            'Наша память хранит крыловские образы, и мы часто\n' + 'обращаемся к ним в самых разных жизненных ситуациях',
        },
        {
          id: 4,
          answer_text: 'Такие живописные зарисовки учат отличать добро от зла, видеть ' + 'невежество, глупость и жадность.',
        },
      ],
    },
    {
      id: 5,
      task_order: 5,
      type_task: 'order_input',
      task_lesson: { lesson_name: 'Математика' },
      task_question: {
        text_question:
          'Ксюша собирала пазлы. Первый пазл, который она собрала,\n' +
          'состоял из 400 деталей, а в каждом следующем пазле было на 100\n' +
          'деталей больше, чем в предыдущем. Всего Ксюша собрала шесть\n' +
          'пазлов.',
      },
      task_answer: [
        { id_answer: 1, answer_text: 'a) Сколько деталей было в пятом пазле, собранном Ксюшей?' },
        {
          id_answer: 2,
          answer_text: 'б) Сколько всего деталей установила Ксюша, правильно собрав все\n' + 'шесть пазлов?',
        },
      ],
    },
    {
      id: 6,
      task_order: 6,
      type_task: 'input',
      task_lesson: { lesson_name: 'Математика' },
      task_question: {
        text_question:
          'Ширина ящика равна 45 см. Какое наибольшее число таких ' +
          'ящиков может быть поставлено в один ряд на полку, ширина которой' +
          'составляет 3 метра 50 сантиметров?',
      },
      task_answer: [{}],
    },
    {
      id: 7,
      task_order: 7,
      type_task: 'image',
      task_lesson: { lesson_name: 'Математика' },
      task_question: {
        text_question:
          'На клетчатой бумаге нарисован квадрат, а' + 'ниже некоторая фигура. Площадь квадрата равна' + '32 кв. см.',
        subtext_question:
          'Найди площадь этой фигуры.\n' +
          'Ответ дай в квадратных сантиметрах.\n' +
          'В ответе писать единицы измерения не нужно.\n',
        img_question: task_img,
      },
      task_answer: [
        { id: 1, answer_text: 'Найдите общую площадь этой фигуры.' },
        { id: 2, answer_text: 'Ответ дай в квадратных сантиметрах.' },
        { id: 3, answer_tooltip: 'В ответе писать единицы измерения не нужно' },
      ],
    },
    {
      id: 8,
      task_order: 8,
      type_task: 'resolve',
      task_lesson: { lesson_name: 'Математика' },
      task_question: {
        text_question:
          'Из города выехал мотоциклист со скоростью 70 км/ч. Через\n' +
          'некоторое время из того же города в противоположном направлении\n' +
          'выехал другой мотоциклист со скоростью 80 км/ч. Когда первый\n' +
          'мотоциклист проехал 490 км, расстояние между мотоциклистами\n' +
          'было 810 км. На сколько часов раньше выехал первый мотоциклист?',
        subtext_question: 'На сколько часов раньше выехал первый мотоциклист? ',
      },
      task_answer: [
        { id_answer: 1, answer_text: 'На сколько часов раньше выехал первый мотоциклист? ' },
        { id_answer: 2, answer_tooltip: 'Запишите полное решение и ответ' },
      ],
    },
    {
      id: 9,
      task_order: 9,
      type_task: 'wordorder_input',
      task_lesson: { lesson_name: 'Английский язык' },
      task_question: {
        text_question: 'Поставь слова в правильном порядке так, чтобы получились ' + 'вопросительные предложения, и запиши их',
        subtext_question: 'Не забудь написать первое слово с заглавной буквы.',
      },
      task_answer: [
        { answer_tooltip: 'Запишите полное решение и ответ' },
        { answer_example: '0.name is what your?' },
        { id_answer: 1, answer_text: 'You do breakfast what have time ?' },
        { id_answer: 2, answer_text: 'Summer last you go did where?\n' },
        { id_answer: 3, answer_text: 'Your names what parents’ are?' },
        { id_answer: 4, answer_text: 'Fridge in butter there any is the?' },
      ],
    },
  ]

  const generateTask = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    let result = Math.floor(Math.random() * (max - min)) + min //Максимум не включается, минимум включается
    setGenerateNumber(result)
  }

  const nextTask = () => {
    if (count < task.length + 1) {
      setCount(count + 1)
    }
  }

  const prevTask = () => {
    if (count >= 1) {
      setCount(count - 1)
    }
  }

  const openTicket = () => {
    setTaskList(true)
    generateTask(1, 100)
    setTicket(true)
  }

  const openTask = () => {
    setCount(count + 1)
    setTicket(false)
    setShowTask(true)
  }

  return (
    <ScreenSizeContainer noPadding>
      {userData && (
        <>
          <Header data={userData} organization={own_organization} />
          <div className={styles.container}>
            <div className={styles.left}>
              <WebRtcTesting />
            </div>
            <div className={styles.right}>
              <div className={styles.task}>
                {count === 0 && (
                  <audio autoPlay='autoplay'>
                    <source src={`http://kadet.edumil.ru/sound/voice_${count}.mp3`} />
                  </audio>
                )}
                <div className={styles.task_wrapper}>
                  {!taskList && (
                    <div className={styles.choose_ticket}>
                      <div>
                        <h2>Вступительные испытания 2021</h2>
                        <p>
                          Формирование варианта задания для каждого кандидата происходит автоматически без участия человека.{' '}
                          <br />
                          Все электронные материалы и видеозапись ответов кандидата поступают в базу данных LMS Школа.
                        </p>
                      </div>
                      <Button label={'ВЫБОР БИЛЕТА'} onClick={() => openTicket()} />
                    </div>
                  )}
                  {ticket && (
                    <div className={styles.ticket_wrapper}>
                      <div className={styles.ticket_number}>
                        <h4>Билет №</h4>
                        <span className={styles.generate_number}>{generateNumber}</span>
                        <div onClick={() => generateTask(1, 100)} className={styles.btn_repeat}>
                          <img src={repeat} alt='' />
                          Другой билет
                        </div>
                        <div className={styles.ticket_list}>
                          <div className={styles.ticket_item}>
                            <p>Математика</p>
                            <span>2 задания</span>
                          </div>
                          <div className={styles.ticket_item}>
                            <p>Русский язык</p>
                            <span>2 задания</span>
                          </div>
                          <div className={styles.ticket_item}>
                            <p>Иностранный язык</p>
                            <span>4 задания</span>
                          </div>
                        </div>
                      </div>
                      <Button label={'Начать'} onClick={() => openTask()} />
                    </div>
                  )}

                  {showTask && task.length >= count && (
                    <div className={styles.taskContainer}>
                      {taskList &&
                        task.map(item =>
                          item.task_order === count ? (
                            <div>
                              <audio autoPlay='autoplay'>
                                <source src={`http://kadet.edumil.ru/sound/voice_${count}.mp3`} />
                              </audio>
                              <TaskTesing
                                data={item}
                                type={item.type_task}
                                lesson={item.task_lesson}
                                question={item.task_question}
                                answer={item.task_answer}
                              />
                            </div>
                          ) : (
                            console.log('err')
                          )
                        )}
                      <TaskControls data={task} count={count} prevTask={prevTask} nextTask={nextTask} />
                    </div>
                  )}
                  {task.length < count && (
                    <div className={styles.last_task}>
                      <p>
                        Вы завершили прохождение тестовой части вступительных испытаний! Так же будет выглядеть и собеседование,
                        удачи в прохождении вступительных испытаний!
                      </p>
                      <Link to={'/'}>
                        <Button label={'Готово'} colorType={'blue'} />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ScreenSizeContainer>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(TestingContainer)

import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'shards-ui/dist/css/shards.min.css'
import 'react-notifications/lib/notifications.css'
import './App.css'
import { configureStore } from './model/store'
import { configureApi } from './model/api'
import { Provider } from 'react-redux'
import HomeContainer from './components/pages/Home'
import AuthContainer from './components/pages/Auth'
import ProfileContainer from './components/pages/Profile'
import EnrollmentContainer from './components/pages/Enrollment'
import PrivateRoute from './components/utils/PrivateRoute'
import Layout from './components/utils/Layout'
import { NotificationContainer } from 'react-notifications'
import TestingContainer from './components/pages/Testing'

export const store = configureStore()
export const api = configureApi(store)

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route
              path={'/'}
              render={() => (
                <Layout>
                  <Route exact path='/' component={HomeContainer} />
                  <Route exact path='/auth' component={AuthContainer} />
                  <PrivateRoute exact path='/profile' component={ProfileContainer} />
                  <PrivateRoute exact path='/enrollment' component={EnrollmentContainer} />
                  <PrivateRoute exact path='/testing' component={TestingContainer} />
                </Layout>
              )}
            />
          </Switch>
        </BrowserRouter>
      </Provider>
      <NotificationContainer />
    </>
  )
}

export default App

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import ios_image from '../../../images/iPhone.png'
import androind_image from '../../../images/Android.png'
import pc_image from '../../../images/PC.png'

import styles from './Home.module.css'
import Container from '../../utils/Container'
import ScreenSizeContainer from '../../utils/ScreenSizeContainer'
import { Link, useHistory } from 'react-router-dom'
import LeftTitles from '../../utils/LeftTitles'

import pdfIcon from '../../../images/pdf.svg'
import { createNotification } from '../../../helpers/createNotification'
import { Button } from 'shards-react'
import Modal from '../../utils/Modal'

import adapter from 'webrtc-adapter'

const mapStateToProps = state => ({
  authData: state.auth,
})

//const mapDispatchToProps = {};

const HomeContainer = ({ authData }) => {
  const [videoModal, setVideoModal] = useState(false)
  const [modalBrowser, setModalBrowser] = useState(true)
  const mainContainer = useRef(null)
  const lg = useRef(null)
  const xs = useRef(null)
  const authDataExists = authData.base && authData.applicant && authData.otp
  let history = useHistory()

  const openModal = () => {
    setVideoModal(true)
  }
  const closeModal = () => {
    setVideoModal(false)
  }

  const closeModalBrowser = () => {
    setModalBrowser(false)
  }

  useEffect(() => {
    if (authDataExists) {
      history.push('/profile')
    }
  }, [])

  const getDesctopVersion = () => {
    mainContainer.current.style.width = '1920px'
    lg.current.style.display = 'block'
    xs.current.style.display = 'none'
  }

  return (
    <div ref={mainContainer}>
      {adapter.browserDetails.browser !== 'chrome' && modalBrowser && (
        <div className={'switchModal'}>
          <Modal closeModal={closeModalBrowser}>
            <h4>Необходимо использовать браузер Google Chrome</h4>
            <a href={'https://www.google.com/intl/ru_ru/chrome/'}>Перейти к скачиванию</a>
          </Modal>
        </div>
      )}
      <div className={'hidden-xs'} ref={lg}>
        <ScreenSizeContainer>
          <h1 className={styles.appHeadline}>Вступительные испытания - 2021</h1>
          {videoModal && (
            <Modal closeModal={closeModal}>
              <video autoplay='autoplay' controls='controls' className={'img-responsive'}>
                <source src={'https://kadet.edumil.ru/promo.mp4'} />
              </video>
            </Modal>
          )}
          <Container>
            <div className={styles.content}>
              <LeftTitles />
              <div className={styles.right}>
                <div className={styles.right_title}>
                  Выберите тип оборудования, на котором Вы готовы пройти дистанционное онлайн-собеседование:
                </div>
                <div className={styles.platform_tiles}>
                  <div className={styles.tile}>
                    <a
                      href={'https://apps.apple.com/us/app/id1516151273'}
                      target={'_blank'}
                      rel='noopener noreferrer'
                      className={styles.tile_image}
                    >
                      <div className={styles.tile_inner} style={{ backgroundImage: 'url(' + ios_image + ')' }} />
                    </a>
                    <p>
                      Смартфон или планшет, на базе операционной системы iOS, имеющий встроенные web-камеру, микрофон и динамик
                    </p>
                  </div>
                  <div className={styles.tile}>
                    <a
                      href={'https://play.google.com/store/apps/details?id=com.nintegra.forwardcadet'}
                      target={'_blank'}
                      rel='noopener noreferrer'
                      className={styles.tile_image}
                    >
                      <div className={styles.tile_inner} style={{ backgroundImage: 'url(' + androind_image + ')' }} />
                    </a>
                    <p>
                      Смартфон или планшет, на базе операционной системы ANDROID, имеющий встроенные web-камеру, микрофон и
                      динамик
                    </p>
                    <a href={'https://kadet.edumil.ru/kadet.apk'} className={styles.download_apk}>
                      Скачать APK приложение для Android
                    </a>
                  </div>
                  <div className={styles.tile}>
                    <Link to={'/auth'} className={styles.route}>
                      <div className={styles.tile_image}>
                        <div className={styles.tile_inner} style={{ backgroundImage: 'url(' + pc_image + ')' }}></div>
                      </div>
                    </Link>
                    <p>
                      Персональный компьютер или ноутбук, оборудованный встроенной или внешней web-камерой, встроенным или внешним
                      микрофоном, встроенными или внешними динамиками
                    </p>
                  </div>
                </div>
                <div className={styles.pdfLink}>
                  <div className={styles.phone_block}>
                    <p>Телефон горячей линии </p>
                    <a href='tel:88003331565' className={styles.submenu_tel}>
                      {' '}
                      8-800-333-1565
                    </a>
                  </div>
                  <Button onClick={() => openModal()} className={styles.btn_video} theme='light' className={'home_link mr-3'}>
                    Информационный ролик
                  </Button>
                  <a href='https://kadet.edumil.ru/manual.pdf' target={'_blank'} className={'home_link'}>
                    <img src={pdfIcon} alt='' />
                    Скачать инструкцию PDF
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </ScreenSizeContainer>
      </div>

      <div className={'visible-xs mobile-link_container'} ref={xs}>
        <div className={styles.mobile_link}>
          <p>Министерство обороны Российской Федерации</p>
          <p className={'mb-4'}>Вперёд, кадет!</p>
          <div className=''>
            <a href={'https://apps.apple.com/us/app/id1516151273'}>Открыть IOS приложение</a>
          </div>
          <div>
            <a href={'https://play.google.com/store/apps/details?id=com.nintegra.forwardcadet'}>Открыть Android приложение</a>
          </div>
          <div>
            <a href={'https://kadet.edumil.ru/kadet.apk'}>Скачать APK приложение для Android</a>
          </div>
          <a onClick={getDesctopVersion}>Версия для ПК</a>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, null)(HomeContainer)
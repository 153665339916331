import {combineReducers} from "redux";
import {AuthReducer} from "../common/Auth/reducers/AuthReducer";
import {UserReducer} from "../common/User/reducers/userReducer";
import {TaskReducer} from "../pages/Enrollment/reducers/TaskReducer";
import {OrganizationsReducer} from "../common/Organizations/reducers/organizationsReducer";
import {SentAnswerReducer} from "../common/sentAnswers/reducers/sentAnswersReducer";

export const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    task: TaskReducer,
    orgList: OrganizationsReducer,
    messages: SentAnswerReducer
});

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import InputMask from 'react-input-mask'
import styles from './AuthForm.module.css'

import no_user from '../../../../images/nouser.png'
import { api } from '../../../../App'
import { BASE_PARAMS } from '../../../../constants/api'
import { Alert, Button, FormInput, FormSelect } from 'shards-react'

const AuthForm = ({ orgList, errorMessage, setAuthData }) => {
  const inputCode = useRef(null)

  const checkEmail = email => {
    //паттерн +7(ddd)ddd-dd-dd
    // const regExp = /^((7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
    const regExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regExp.test(email)
  }

  let history = useHistory()

  const [formData, setFormData] = useState({
    email: '',
    base: '',
    children: [],
    applicant: '',
    code: '',
  })
  const [error, setError] = useState(errorMessage)
  const [OTPTimer, setOTPTimer] = useState(0)

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [errorMessage])

  useEffect(() => {
    if (checkEmail(formData.email)) {
      getApplicantListByEmail(formData.email)
    }
  }, [formData.email])

  useEffect(() => {
    let interval
    if (OTPTimer > 0) {
      interval = setInterval(() => {
        setOTPTimer(OTPTimer - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [OTPTimer])

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onOrgChange = e => {
    setFormData({
      base: e.target.value,
      email: '',
      children: [],
      applicant: '',
      code: '',
    })
    setError('')
  }

  const setCountdown = () => {
    setOTPTimer(60)
  }

  const setAuthParams = (base, applicant, otp) => {
    setAuthData({
      base,
      applicant,
      otp,
    })
    localStorage.setItem('base', base)
    localStorage.setItem('applicant', applicant)
    localStorage.setItem('otp', otp)
  }

  const getApplicantListByEmail = () => {
    const { base, email } = formData

    if (!base && !email) return

    const params = {
      ...BASE_PARAMS,
      action: 'applicant.listbyemail',
      base,
      email,
    }

    api
      .get('', { params })
      .then(r => {
        const foundedApplicants = r.data.Answer.Data
        if (foundedApplicants) {
          setFormData({
            ...formData,
            applicant: foundedApplicants.length === 1 ? foundedApplicants[0].xp_key : '',
            children: foundedApplicants,
          })
          setError('')
        } else {
          setError('С этой почтой нет связаннных кандидатов')
        }
      })
      .catch(err => {
        setError('Не удалось загрузить список детей')
      })
  }

  const getOTPCode = () => {
    const { base, applicant } = formData

    const params = {
      ...BASE_PARAMS,
      action: 'applicant.requestOTPByEmail',
      base,
      applicant,
    }

    api
      .get('', { params })
      .then(r => {
        setCountdown()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onSubmit = () => {
    const { base, applicant, code } = formData

    const params = {
      ...BASE_PARAMS,
      action: 'applicant.sendOTP',
      base,
      applicant,
      otp: code,
    }

    api
      .get('', { params })
      .then(r => {
        setAuthParams(base, applicant, code)
        history.push('/profile')
      })
      .catch(err => {
        setError('Неверный код авторизации')
      })
  }

  return (
    <div className={styles.container}>
      <div className={styles.user}>
        <img src={no_user} alt='' />
      </div>

      <h4>Пожалуйста, авторизуйтесь</h4>

      <FormSelect name='base' onChange={onOrgChange} value={formData.base} className={'mb-2'} disabled={!orgList.length}>
        <option value=''>Выберите организацию</option>
        {orgList.map(item => (
          <option key={item.Name} value={item.Name}>
            {item.Title}
          </option>
        ))}
      </FormSelect>

      {formData.base && (
        <div className={styles.phoneInput}>
          <InputMask value={formData.email} onChange={onChange}>
            {inputProps => (
              <FormInput
                type='text'
                name={'email'}
                value={formData.email}
                onChange={onChange}
                placeholder={'Email'}
                className={'mb-2'}
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
      )}

      {checkEmail(formData.email) && !!formData.children.length && (
        <>
          {formData.children.length > 1 && (
            <FormSelect name='applicant' onChange={onChange} value={formData.applicant} className={'mb-2'}>
              <option value=''>Выберите ученика</option>
              {formData.children.map(item => (
                <option key={item.xp_key} value={item.xp_key}>
                  {item.Child_LastName} {item.Child_FirstName} {item.Child_MidName}
                </option>
              ))}
            </FormSelect>
          )}

          {formData.children.length === 1 && (
            <FormInput
              type='text'
              disabled
              className={'mb-2'}
              value={`${formData.children[0].Child_LastName} ${formData.children[0].Child_FirstName} ${formData.children[0].Child_MidName}`}
            />
          )}

          {formData.applicant && (
            <>
              <div className={styles.phoneInput}>
                <FormInput
                  type='number'
                  name='code'
                  value={formData.code}
                  onChange={onChange}
                  className={'mb-2'}
                  ref={inputCode}
                  autoFocus
                />
                {OTPTimer ? (
                  <div className={classNames(styles.otp_timer, 'mb-2')}>
                    Повторный пароль можно запросить через {OTPTimer} сек.
                  </div>
                ) : (
                  <button onClick={getOTPCode}>Получить одноразовый пароль</button>
                )}
              </div>
              <Button disabled={formData.code < 4} onClick={onSubmit} block>
                Отправить
              </Button>
            </>
          )}
        </>
      )}

      {error && (
        <Alert theme='danger' className={styles.error}>
          {error}
        </Alert>
      )}

      <Link to={'/'}>
        <span className={styles.goBack}>Назад к выбору платформы</span>
      </Link>
    </div>
  )
}

AuthForm.propTypes = {
  orgList: PropTypes.array,
}

export default AuthForm

export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const ERROR = "_ERROR";

export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const RESET_AUTH_DATA = "RESET_AUTH_DATA";

export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const SET_USER_DATA = "SET_USER_DATA";

export const TASK_REQUEST = "TASK_REQUEST";
export const SET_TASK_SEARCH_DATA = "SET_TASK_SEARCH_DATA";

export const ORG_LIST_REQUEST = "ORG_LIST_REQUEST";

export const SENT_MESSAGES = "SENT_MESSAGES"
import React from 'react'
import PropTypes from 'prop-types'
import styles from './modal.module.css'
import close from './close.svg'

const Modal = ({ children, closeModal }) => {
  return (
    <div className={styles.modal_bg}>
      <div className={styles.modal_container}>
        <button className={styles.close_modal} onClick={closeModal}>
          <img src={close} alt='' />
        </button>
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
}

export default Modal

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ScreenSizeContainer from '../../utils/ScreenSizeContainer'
import styles from './Auth.module.css'
import AuthForm from './Form'
import Container from '../../utils/Container'
import { setAuthData } from '../../../model/common/Auth/actions'
import { Link, useHistory } from 'react-router-dom'

const mapStateToProps = state => ({
  orgList: state.orgList,
  authData: state.auth,
})

const mapDispatchToProps = {
  setAuthData,
}

const AuthContainer = ({ setAuthData, orgList, authData }) => {
  const authDataExists = authData.base && authData.applicant && authData.otp
  let history = useHistory()

  useEffect(() => {
    if (authDataExists) {
      history.push('/profile')
    }
  }, [])

  return (
    <ScreenSizeContainer>
      <h1 className={styles.appHeadline}>Вступительные испытания - 2021</h1>
      <Container>
        <AuthForm orgList={orgList} setAuthData={setAuthData} />
      </Container>
    </ScreenSizeContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)


import React, {useEffect} from 'react';

import styles from '../../Enrollment/WebRtc/video.module.css';



const WebRtcTesting = () => {

    let localVideo;
    let localStream;


    useEffect(() => {
        pageReady();

        return () => {
            localStream.getTracks().forEach(track => track.stop());
        }
    }, []);


    function pageReady() {
        localVideo = document.getElementById('localVideo');
        let constraints = {
            video: true,
            audio: true,
        };

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints).then(getUserMediaSuccess).catch(errorHandler);
        } else {
            alert('Your browser does not support getUserMedia API');
        }
    }

    function getUserMediaSuccess(stream) {
        localStream = stream;
        localVideo.srcObject = stream;
    }

    function errorHandler(error) {
        console.log(error);
    }

    return (
        <div className={styles.container}>
            <video id="localVideo" autoPlay muted className={styles.own}/>
            <video id="remoteVideo" autoPlay className={styles.interlocutor} loop={'loop'} autoplay={'autoplay'}>
                <source src={'http://kadet.edumil.ru/test_comission.mov'} />
            </video>
        </div>
    );
};


export default WebRtcTesting;